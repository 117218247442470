import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { defaultPaginationIndex, pageSizeOptions } from '@/types/pagination';
import { useDataTablePagination } from '@/hooks/usePagination';
import { usePathData, useTree } from '@/hooks/data/tree/useTree';
import { useUserSettings } from '@/context/UserSettingsContext';
import { useSearchQuery } from '@/hooks/useSearchQuery';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { GalleryLayout } from '@/components/layouts/gallery-layout';
import { useAssetList } from '@/hooks/data/assets/useAssetList';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { EntityType } from '@/types/entity';
import { DamLayout } from '@/components/layouts/layout';
import { useAuth0 } from '@auth0/auth0-react';
import { useSort } from '@/hooks/useSort';
import { Tenant } from '@/types/tenant';
import { EmptyLayout } from './empty-layout';
import { FolderX, Plus } from 'lucide-react';
import { useDialog } from '@/context/DialogContext';

export const HomeComponent = ({ tenant }: { tenant: Tenant }) => {
  const setSelectedFolder = useTreeStore((state) => state.setSelectedFolder);
  const setSelectedAlbum = useTreeStore((state) => state.setSelectedAlbum);
  const folder = useTreeStore((state) => state.selectedFolder);
  const album = useTreeStore((state) => state.selectedAlbum);
  const { openModal } = useDialog();

  const { isAuthenticated } = useAuth0();
  const {
    tree,
    isRootNodesLoading,
    includeSubFolderAssets,
    sidebarTreeOpenIds,
    firstTimeLoaded,
    setFirstTimeLoaded,
    setSidebarTreeOpenIds,
  } = useTree();
  const { assetsPerPage } = useUserSettings();
  const { query, push } = useRouter();
  const { pathSlugs, uiSection } = useCurrentPage();
  const { folderIds, folderId, albumId } = usePathData(
    pathSlugs ?? [],
    firstTimeLoaded && uiSection === 'browse', // Before we can properly sort out
  );
  const {
    pagination,
    setPagination,
    offset,
    limit,
    ready: paginationReady,
    setQueryParams,
  } = useDataTablePagination(EntityType.Asset);
  const { sort, handleOnSortChange, handleOnSortOrderChange, ready: sortReady } = useSort(EntityType.Asset);
  const { queryString, debouncedSearch, showFilterInput, setShowFilterInput } = useSearchQuery();
  const { error, assets } = useAssetList(
    {
      offset,
      limit,
    },
    folder && String(folder),
    album && String(album),
    sort,
    queryString,
    { enabled: isAuthenticated && Boolean(sortReady) && Boolean(paginationReady) },
    includeSubFolderAssets,
  );

  useEffect(() => {
    if (firstTimeLoaded && (folderIds.length > 0 || folderId || albumId)) {
      setFirstTimeLoaded(false);
      setSelectedAlbum(albumId);
      setSelectedFolder(folderId);
      setSidebarTreeOpenIds([...sidebarTreeOpenIds, ...folderIds]);
    }
  }, [folderIds, folderId, albumId]);

  useEffect(() => {
    if (pagination && setPagination) {
      setPagination({ pageIndex: defaultPaginationIndex, pageSize: pagination.pageSize });
    }
  }, [sort?.value, sort?.order, queryString]);

  useEffect(() => {
    if (pagination && sort) {
      setQueryParams(pagination.pageIndex + 1, pagination.pageSize, sort.value, sort.order, queryString);
    }
  }, [sort?.value, sort?.order, pagination?.pageIndex, pagination?.pageSize, queryString]);

  useEffect(() => {
    if (isAuthenticated && !isRootNodesLoading && tree) {
      void push(
        {
          // Select first folder from the tree on initial load
          query: { pageIndex: 1, pageSize: assetsPerPage ?? pageSizeOptions[0], ...query },
        },
        undefined,
        { shallow: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, tree]);

  return (
    <DamLayout
      handleOnSortChange={handleOnSortChange}
      setPagination={setPagination}
      assets={assets?.assets}
      tenant={tenant}
    >
      <GalleryLayout
        data={assets}
        sort={sort}
        onError={error}
        handleOnSortChange={handleOnSortChange}
        handleOnSortOrderChange={handleOnSortOrderChange}
        queryString={queryString}
        handleOnSearch={debouncedSearch}
        showFilterInput={showFilterInput}
        setShowFilterInput={setShowFilterInput}
        pagination={pagination}
        setPagination={setPagination}
        emptyState={
          tree.length === 0 ? (
            <EmptyLayout
              icon={FolderX}
              title="No Folder"
              message="You don’t have any folders at the moment. Folders are where your assets are stored, create one to begin."
              onClick={() => openModal('editCreateFolderAlbum', 'createFolder')}
              buttonIcon={Plus}
              buttonText={'Create Folder'}
            />
          ) : null
        }
      />
    </DamLayout>
  );
};
